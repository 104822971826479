.hs-form-field {
  margin-bottom: 2rem;
}

.hs-form-field label {
  color: #242a33;
  font-size: 0.875rem;
  font-weight: 500;
}

.hs-form-field input,
.hs-form-field textarea {
  border: 1px solid #e2e8f0;
  border-radius: 99rem;
  padding: 0.5rem 0.75rem;
  width: 100%;
  background-color: #f6f8fa;
}

.hs-error-msgs,
label.hs-error-msg.hs-main-font-element {
  color: #7c98b6;
  font-size: 0.875rem;
  font-weight: 500;
}

input.hs-button.primary {
  background-color: #1a1a47;
  border-radius: 99rem;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 12px 24px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
